const getProductImg = val => {
  if (val) {
    const v = val.split(';')[0]
    return process.env.VUE_APP_IMG_URL + 'product/' + v
  }
}

const Two = val => {
  val = Number(val).toFixed(2);
  return val;
}

export default {
  getProductImg,
  Two
}
