import { request } from './request'

// 发起各种请求
const reqArr = ['get', 'post', 'put', 'delete', 'patch']
const modules = {}
reqArr.forEach(item => {
  modules[item] = (url, data = {}) => {
    const requestObj = {
      method: item,
      url
    }
    if (item === 'get') {
      requestObj.params = data
    } else {
      requestObj.data = data
    }
    return request(requestObj)
  }
})

export default modules
