module.exports = {
  allClasses: '全部分类',
  searchAmazon: '请输入内容',
  helloSignin: '您好，登录',
  accountLists: '账户及心愿单',
  returns: '退货',
  orders: '与我的订单',
  cart: '购物车',
  all: '全部',
  todaysDeals: '今日特价',
  customerService: '客户服务',
  registry: '礼品心愿单',
  giftCards: '礼品卡',
  sell: '全球开店',
  ShopGreat: '立即选购超值优惠商品',
  TodaysSpecial: '今日特惠 同步全球 天天低价',
  prime: 'Prime会员甄选',
  FourCountries: '四国精选 地道好货',
  TrendNewProduct: '潮流新品研究所',
  GoodCoupons: '优惠好券 省钱必逛',
  VoucherCollection: '领券中心',
  ExclusiveBenefits: '新人专享福利',
  MonthlyPremium: '超值福利月月领',
  RealTime: '实时排行榜',
  OlayDiscount: '奥莱折扣低至1折',
  DiscountSelection: '折扣甄选攻略',
  AnnualLow: '年度低价爆款',
  discountZone3: '3-5折专区',
  discountZone5: '5-7折专区',
  BuyMore: '多买多省 2件95折 3件92折',
  Meiya: '美亚专区',
  Deya: '德亚专区',
  Riya: '日亚专区',
  Yingya: '英亚专区',
  primeExclusive: 'Prime会员专属9折优惠',
  Enjoyup: '1件即享最高9折',
  LowPriceItem: '美亚低价单品',
  YingyaUses: '英亚使用好物',
  DEYABIBU: '德亚必买爆款',
  OpeningTheAI: '开启AI之门 智能电子 89元起',
  HomeDecoration: '家装馆',
  KusaCycling: '酷飒骑行运动装备 39元起',
  ComputerPavilion: '电脑馆',
  ColorfulAnime: '缤纷二次元',
  UnlockSpring: '解锁春潮板鞋',
  AmazonOpensStores: '亚马逊全球开店',
  RecommendedBySelected: '精选品类推荐',
  TOP500: 'TOP500必读',
  readGood: '好剧读起来',
  HotNewBooks: '热门新书抢鲜读',
  TheMonthly: '电子书连续包月仅12元',
  ViewDetails: '查看详情',
  TreasureOfToday: '今日特惠 镇店之宝',
  seeMore: '查看更多',
  ElectronicDigital: '电子数码 爆款促销',
  ViewPersonalized: '查看个性化推荐',
  SignInNow: '立即登录',
  NewCustomers: '新客户',
  FreeRegistration: '免费注册',
  BackToTop: '回到顶部',
  GetTo: '了解我们',
  TalentRecruitment: '人才招聘',
  AmazonScience: '亚马逊科学',
  CooperationInformation: '合作信息',
  IWant: '我要开店',
  JoinTheAlliance: '加入联盟',
  HelpCenter: '帮助中心和购物指南',
  PaymentAssistance: '付款帮助',
  RefundAssistance: '退款帮助',
  ReturnsExchanges: '退货与换货',
  HelpCenter1: '帮助中心',
  AmazonAPP: '亚马逊APP下载',
  // seeMore: '互联网药品信息服务资格证书(京)-非经营性-2018-0231',
  // seeMore: '增值电信业务经营许可证:合字B2-20090004',
  // seeMore: '京公网安备11010502030232号',
  // seeMore: '营业执照:91110000717883277U',
  // seeMore: '京ICP备11014788号',
  // seeMore: '出版物经营许可证',
  // seeMore: '使用条件',
  // seeMore: '隐私声明',
  // seeMore: '或其附属公司',
}