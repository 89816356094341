import http from '../require'

// 订单
export const getOrders = (data) => http.get('/merchant-orders', data)
// 订单分析
export const getOrdersChat = (data) => http.get('/merchants/chart', data)

export const getOrdersProducts = (data) => http.get('/merchant-products', data)

export const getFinance = (data) => http.get('/merchants/finance-data', data)

export const getFinance1 = (id,data) => http.get('/merchants/'+id, data)

export const reqWithdrawals = (type, data) => type==='post' ? http.post('/withdrawals', data) : http.get('/withdrawals', data)
