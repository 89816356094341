import Vue from 'vue'
import {
  Input, Option, Select, Button, Popover, Carousel, CarouselItem, Rate, Tree, Divider, Checkbox, InputNumber, Progress,
  Message, Menu, Submenu, MenuItem, Tabs, TabPane, Switch, Table, TableColumn, Pagination, DatePicker, Radio, RadioGroup,
  Step, Steps} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Input).use(Option).use(Select).use(Button).use(Popover).use(Carousel).use(CarouselItem).use(Rate).use(Tree).use(Divider).use(Checkbox).use(InputNumber).use(Progress)
Vue.use(Menu).use(Submenu).use(MenuItem).use(Tabs).use(TabPane).use(Switch).use(Table).use(TableColumn).use(Pagination).use(DatePicker).use(Radio).use(RadioGroup)
Vue.use(Step).use(Steps)
Vue.prototype.$message = Message
