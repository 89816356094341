// import * as http from '../require'
import http from '../require'
// 验证名称
export const memberinfoCheck = data => http.put('memberinfo/check-account', data)
// 注册
export const memberinfoMemberinfos = data => http.post('/memberinfos', data)
// 登录
export const siteLogin = data => http.post('/site/login', data)
// 设置密码
export const setPwd = data => http.patch('/memberinfos/set-password', data)
// 纸密码验证
export const enwordsCheck = data => http.patch('/memberinfos/enwords-check', data)
// 恢复纸密码
export const getWallet = data => http.patch('/memberinfos/getwallet', data)
// 获取验证码
export const getVerifyCode = (phone, type, method) => http.get(`/site/code-verify?phone=${phone}&type=${type}&method=${method}`)
// 修改密码
export const forgetPwd = data => http.patch('/memberinfos/forget-password', data)
// 会员资料
export const getUserinfo = data => http.get('/merchants/view-my', data)
