import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
// 在路由加载的时候首次进来要按需加载路由，首页加载慢的问题，不要使用import换成require
export const constantRouteMap = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: resolve => require(['@/views/index'], resolve),
    name: 'index',
    meta:{
      title:'亚马逊-网上购物商城：要网购，就来Z.cn'
    }
  },
  {
    path: '/login',
    component: resolve => require(['@/views/login/index'], resolve),
    name: 'login',
    meta:{
      title:'亚马逊-登录'
    }
  },
  {
    path: '/login/step2',
    component: resolve => require(['@/views/login/step2'], resolve),
    name: 'step2',
    meta:{
      title:'亚马逊-登录'
    }
  },
  {
    path: '/classes/index',
    component: resolve => require(['@/views/classes/index'], resolve),
    name: 'classes',
    meta:{
      title:'亚马逊-网上购物商城：要网购，就来Z.cn'
    }
  },
  {
    path: '/product-detail',
    component: resolve => require(['@/views/productDetail/index'], resolve),
    name: 'productDetail',
    meta:{
      title:'亚马逊-网上购物商城：要网购，就来Z.cn'
    }
  },
  {
    path: '/order/index',
    component: resolve => require(['@/views/order/index'], resolve),
    name: 'order',
    meta:{
      title:'seller central'
    }
  },
  {
    path: '/order/shipment',
    component: resolve => require(['@/views/order/shipment/index'], resolve),
    name: 'shipment',
    meta:{
      title:'seller central'
    }
  },
  {
    path: '/order/controlPanel',
    component: resolve => require(['@/views/order/controlPanel/index'], resolve),
    name: 'controlPanel',
    meta:{
      title:'seller central'
    }
  },
  {
    path: '/order/products',
    component: resolve => require(['@/views/order/products/index'], resolve),
    name: 'products',
    meta:{
      title:'seller central'
    }
  },
  {
    path: '/order/logs',
    component: resolve => require(['@/views/order/logs/index'], resolve),
    name: 'logs',
    meta:{
      title:'seller central'
    }
  },
  {
    path: '/order/withdrawal',
    component: resolve => require(['@/views/order/withdrawal/index'], resolve),
    name: 'withdrawal',
    meta:{
      title:'seller central'
    }
  },
  {
    path: '/order/withdrawal/apply',
    component: resolve => require(['@/views/order/withdrawal/apply'], resolve),
    name: 'apply',
    meta:{
      title:'seller central'
    }
  }
]
const router = new Router({
  mode: process.env.VUE_APP_MODE, // 根绝环境变量来区分是打包成APP还是网页版查看,history和mode模式
  routes: constantRouteMap
})

router.afterEach(() => {
  window,scrollTo(0,0)
})

router.beforeEach((to, from, next) => {
  to.meta.title && (document.title = to.meta.title);
  next()
});

export default router
