import http from '../require'

// 产品分组
export const getGroups = (data) => http.get('/product-groups?sort=product_group_sort', data)
// 产品
export const getProducts = (data) => http.get('/products', data)
// 产品详情
export const getProductsDes = (data) => http.get('/products/' + data)
// 登录
export const postLogin = (data) => http.post('/site/login', data)
// 品牌
export const getBrandsId = (data) => http.get('/brands/' + data)
