module.exports = {
  allClasses: 'all',
  searchAmazon: 'Search Amazon',
  helloSignin: 'Hello,sign in',
  accountLists: 'Account & Lists',
  returns: 'Returns',
  orders: '& Orders',
  cart: 'Cart',
  all: 'All',
  todaysDeals: "Today's Deals",
  customerService: 'Customer Service',
  registry: 'Registry',
  giftCards: 'Gift Cards',
  sell: 'Sell',
  ShopGreat: 'Shop great deals now',
  TodaysSpecial: "Today's special offer synchronizes with global daily low prices",
  prime: 'Prime Member selection',
  FourCountries: 'Four countries selected authentic goods',
  TrendNewProduct: 'Trend New Product Research Institute',
  GoodCoupons: 'Good coupons, good coupons, money saving, shopping',
  VoucherCollection: 'Voucher collection center',
  ExclusiveBenefits: 'Exclusive benefits for new employees',
  MonthlyPremium: 'Monthly premium benefits',
  RealTime: 'Real-time leaderboard',
  OlayDiscount: 'Olay discount is as low as 10% off',
  DiscountSelection: 'Discount Selection Strategy',
  AnnualLow: 'Annual low price explosion',
  discountZone3: '3-5% discount zone',
  discountZone5: '5-7% discount zone',
  BuyMore: 'Buy More, Save More, 2 Pieces, 95% Off, 3 Pieces, 92% Off',
  Meiya: 'Meiya Region',
  Deya: 'Deya Region',
  Riya: 'Riya Region',
  Yingya: 'Yingya Region',
  primeExclusive: 'Prime Member exclusive 10% discount',
  Enjoyup: 'Enjoy up to 10% off for one piece',
  LowPriceItem: 'Low price item from Meiya',
  YingyaUses: 'Yingya uses good things',
  DEYABIBU: "DEYABIBU's popular model",
  OpeningTheAI: 'Opening the AI Gate Smart Electronics starts at 89 yuan',
  HomeDecoration: 'Home Decoration Hall',
  KusaCycling: 'Kusa cycling sports equipment starts at 39 yuan',
  ComputerPavilion: 'Computer Pavilion',
  ColorfulAnime: 'Colorful anime',
  UnlockSpring: 'Unlock Spring Tide Board Shoes',
  AmazonOpensStores: 'Amazon opens stores worldwide',
  RecommendedBySelected: 'Recommended by selected categories',
  TOP500: 'TOP 500 Required Reading',
  readGood: 'Read a good play',
  HotNewBooks: 'Hot New Books',
  TheMonthly: 'The monthly subscription for e-books is only 12 yuan',
  ViewDetails: 'View Details',
  TreasureOfToday: "Treasure of Today's Special Offer Town Store",
  seeMore: 'See More',
  ElectronicDigital: 'Electronic digital sales promotion',
  ViewPersonalized: 'View personalized recommendations',
  SignInNow: 'Sign in now',
  NewCustomers: 'New customers',
  FreeRegistration: 'Free registration',
  BackToTop: 'Back to top',
  GetTo: 'Get to know us',
  TalentRecruitment: 'Talent recruitment',
  AmazonScience: 'Amazon Science',
  CooperationInformation: 'Cooperation information',
  IWant: 'I want to open a store',
  JoinTheAlliance: 'Join the Alliance',
  HelpCenter: 'Help Center and Shopping Guide',
  PaymentAssistance: 'Payment Assistance',
  RefundAssistance: 'Refund Assistance',
  ReturnsExchanges: 'Returns and Exchanges',
  HelpCenter1: 'Help Center',
  AmazonAPP: 'Amazon APP download'
}