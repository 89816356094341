import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import * as echarts from 'echarts';
import router from './router'
import './plugins/element'
import * as api from './api/api'
import filter from './api/filters'

Vue.config.productionTip = false

// 引入样式
require('./assets/css/common.css')
require('./assets/css/icon.css')

Object.keys(filter).forEach(key => Vue.filter(key, filter[key]))
Vue.use(VueI18n)
const arr = {
  echarts,
  api: api.default
}
for (const key in arr) {
  Object.defineProperty(Vue.prototype, `$${key}`, {
    get: () => arr[key]
  })
}
Vue.prototype.$bus = new Vue()

const i18n = new VueI18n({
  messages: {
    'zh': require('./lang/zh'),
    'en': require('./lang/en')
  },
  locale: 'en'
})

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
